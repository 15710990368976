import React from 'react';
import loadable from '@loadable/component';

const NavbarComponent = loadable(() => import('./navbar/Navbar'));
const HeaderComponent = loadable(() => import('./header/Header'));
const ServicesComponent = loadable(() => import('./services/Services'));
const AboutComponent = loadable(() => import('./about/About'));
const ContactComponent = loadable(() => import('./contact/Contact'));
const FooterComponent = loadable(() => import('./footer/Footer'));
const FAQComponent = loadable(() => import('./faq/FAQ'));
const ReviewComponent = loadable(() => import('./review/Reviews'));


export default function Application() {

    return (
        <div className="applicationContainer">
            <NavbarComponent />
            <HeaderComponent />
            <ServicesComponent />
            <ReviewComponent />
            <AboutComponent />
            <FAQComponent  />
            <ContactComponent />
            <FooterComponent />
        </div>
    );

}
