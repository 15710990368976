import React from 'react';
import ReactDOM from 'react-dom';
import "./i18n";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Application from './components/Application';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
      <Application />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();
