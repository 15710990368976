import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
        en: {
        translations: {
            menu: "Menu",
            services: "Services",
            contact: "Contact",
            FAQ: "FAQ",
            about: "About us",
            aboutTextIntro: "Our mission is to provide superior service to all car enthusiasts, regardless of their type of vehicle. Whether it's modern cars, old-timers or exotic imported models, at NN Auto you can always find the right parts that will provide your vehicle with optimal performance and a long service life. Our team of experts is always available to provide you with the best advice and support when browsing and purchasing auto parts.",
            aboutTextOrigin: "NN Auto was founded in 2007 as a trade that sells auto parts both wholesale and retail. Our store offers a wide range for different foreign and domestic cars. Initially, we focused on providing parts for Opel, Fiat, VW, Lada and Zastava vehicles. However, over the years we have expanded our offer and can now meet the needs for any one brand. Most of the parts we offer are in stock, which means they are available for immediate purchase. This allows us to deliver parts to our customers quickly and efficiently.",
            aboutTextMidSection: "In addition to a large number of parts that are always available to us, we also procure parts on a \"today for today\" basis.  This means that although we may not currently have certain parts in stock, we can order them and deliver them the same day or as soon as possible. Our goal is to provide a comprehensive service and that our customers get everything they need to maintain and repair their vehicles. Our company tries to follow the latest trends and innovations in the automotive industry in order to always offer the best quality and reliabilty in what we sell to our customers.",
            aboutTextConclusion: "Our expert team assures that what we offfer is the best possible quality and that no expense is spared. In addition to selling, we also provide expert advice and support to our customers. Our friendly and trained experts are on hand to help you choose the right parts and answer all your questions and concerns. At NN Auto, we are proud of our long-standing tradition, the quality of our products and the service we provide to our customers. We believe that we have become a reliable partner for all car enthusiasts and vehicle owners. Whether you need a repair part or want to improve the performance of your vehicle, you can rely on us to provide you with the best solution and the support you deserve.",
            subtitle: "Wholesale and retail trade of auto parts",
            service1: "Wide variety of auto parts",
            service1Text: "At NN Auto, we proudly offer a wide range of auto parts for both domestic and foreign vehicles. Our product range covers all major makes and models, ensuring our customers have access to top quality parts for their fleets.",
            service2: "Local and international auto parts ordering",
            service2Text: "In order to meet all the needs of our customers, we have made it possible to order auto parts directly from the country as well as from abroad. Cooperation with several large distribution networks such as Szakal Metal and Stahlgruber gives us access to a wide range of parts and components of the highest quality. Also, in order to expand the offer and always be one step ahead, we procure a specific group of parts from China, which is increasingly establishing itself as a relevant player in the auto industry.",
            service3: "Old-timers",
            service3Text: "In our continuous effort to fulfill all the wishes of our customers, we have introduced the latest offer - the supply of original parts for old timers. Avid vintage car enthusiasts can now find authentic parts for their vintage cars, vehicles that date back to before 1970. Our team of experts is dedicated to finding rare and specific parts to preserve the beauty and authenticity of these wonderful vehicles.",
            faqlabel1:"What types of auto parts do you offer?",
            faqcontent1: "Our offer includes a wide range of auto parts, including parts for various foreign and domestic cars. Regardless of your vehicle, you will find the right parts with us.",
            faqlabel2:"Is it possible to deliver car parts to your home address?",
            faqcontent2: "Yes, we make fast delivery of car parts to the home address through courier service, we offer the option of payment on delivery.",
            faqlabel3:"Is it possible to order from abroad? ",
            faqcontent3: "Delivery from abroad is possible for certain products. Contact us by phone or e-mail to provide you with information about the availability of this service for specific goods.",
            faqlabel4:"What are the supported payment methods?",
            faqcontent4: "We accept cash payments and checks in our store. For delivery, we offer the option of cash on delivery.",
            faqlabel5:"Do you offer a warranty on auto parts?",
            faqcontent5: "Yes, all our auto parts come with a warranty. The warranty can last up to 6 months with a fiscal receipt from an authorized car dealership or in accordance with the warranty provided by the parts manufacturers.",
            faqlabel6:"What is the policy for returning parts and goods?",
            faqcontent6: "For the return of unused parts, a fiscal receipt is required as a confirmation of purchase. If the parts are already installed, the complaint record is required to be completed with the attached fiscal receipt. After a successful complaint procedure, appropriate procedures are applied.",
            faqlabel7:"Do you offer to install certain auto parts on site?",
            faqcontent7: "We do not install car parts in our shop, except for the simplest parts such as wipers, antennas and the like.",
            review: "Reviews",
            serbia: "Serbia",
            monday: "Monday",
            friday: "Friday",
            saturday: "Saturday"

        }
        },
        rs: {
        translations: {
            menu: "Meni",
            services: "Usluge",
            contact: "Kontakt",
            FAQ: "FAQ",
            about: "O nama",
            aboutTextIntro: "Naša misija je pružiti vrhunsku uslugu svim ljubiteljima automobila, bez obzira na vrstu vozila koju voze. Bilo da su u pitanju savremeni automobili, oldtajmeri ili egzotični uvozni modeli, u NN Auto-u možete uvek pronaći prave delove koji će vašem vozilu obezbediti optimalne performanse i dug vek trajanja. Naš tim stručnjaka uvek je na raspolaganju kako bi vam pružio najbolje savete i podršku pri odabiru i nabavci auto delova.",
            aboutTextOrigin: "Firma NN Auto je osnovana 2007. godine kao trgovina koja se bavi prodajom auto delova kako na veliko, tako i na malo. Naša prodavnica nudi širok asortiman auto delova za različite strane i domaće automobile. U početku smo se fokusirali na pružanje delova za vozila marki Opel, Fiat, VW, Lada i Zastava. Međutim, tokom godina smo proširili našu ponudu i sada možemo zadovoljiti potrebe gotovo svih tipova vozila. Većinu delova koje nudimo imamo na lageru, što znači da su dostupni odmah za kupovinu. Ovo nam omogućava da brzo i efikasno isporučimo delove našim klijentima.",
            aboutTextMidSection: "Uz veliki broj delova koji su nam uvek dostupni, takođe vršimo i nabavke delova po principu \"danas za danas\". To znači da, iako određene delove možda nemamo trenutno na lageru, možemo ih naručiti i isporučiti istog dana ili u što kraćem roku. Naš cilj je da pružimo sveobuhvatnu uslugu i da naši kupci dobiju sve što im je potrebno za održavanje i popravku svojih vozila. Naša firma se trudi da prati najnovije trendove i inovacije u automobilskoj industriji kako bismo uvek imali u ponudi najkvalitetnije i najpouzdanije delove.",
            aboutTextConclusion: "Svi naši delovi su pažljivo odabrani od strane stručnog tima kako bismo osigurali da zadovoljavaju najviše standarde kvaliteta. Pored prodaje auto delova, takođe pružamo i stručne savete i podršku našim kupcima. Naš tim ljubaznih i obučenih stručnjaka stoji vam na raspolaganju kako bi vam pomogao u odabiru pravih delova i rešavanju svih vaših pitanja i nedoumica. U NN Auto-u se ponosimo našom dugogodišnjom tradicijom, kvalitetom naših proizvoda i uslugom koju pružamo našim kupcima. Verujemo da smo postali pouzdan partner za sve ljubitelje automobila i vlasnike vozila. Bez obzira na to da li vam je potreban deo za popravku ili sželite da unapredite performanse svog vozila, možete se osloniti na nas da vam pružimo najbolje rešenje i podršku koju zaslužujete.",
            subtitle: "Trgovinska radnja auto delova na veliko i malo",
            service1: "Domaći i strani asortiman",
            service1Text: "U trgovini NN Auto, ponosno nudimo bogat asortiman auto delova kako za domaća, tako i za strana vozila. Naša paleta proizvoda obuhvata sve najvažnije robne marke i modele, obezbeđujući da naši kupci imaju pristup vrhunskim delovima za svoje vozne parkove.",
            service2: "Naručivanje auto delova iz zemlje i inostranstva",
            service2Text: "Kako bismo zadovoljili sve potrebe naših kupaca, omogućili smo mogućnost direktnog naručivanja auto delova kako iz zemlje, tako i iz inostranstva. Saradnja sa nekoliko velikih distributivnih mreža kao što su Szakal Metal i Stahlgruber, omogućava nam pristup širokom spektru delova i komponenti najviše kvalitete. Takođe, kako bismo proširili ponudu i uvek bili korak ispred, vršimo nabavku specifične grupe delova iz Kine, koja se sve više etablira kao relevantan igrač u auto industriji.",
            service3: "Oldtajmeri",
            service3Text: "U našem neprekidnom nastojanju da ispunimo sve želje naših kupaca, uveli smo najnoviju ponudu - nabavku originalnih delova za oldtajmere. Strastveni ljubitelji starih modela automobila sada mogu pronaći autentične delove za svoje oldtajmere, vozila koja datiraju pre 1970. godine. Naš tim stručnjaka posvećen je pronalaženju retkih i specifičnih delova kako bismo očuvali lepotu i autentičnost ovih predivnih vozila.",
            faqlabel1:"Koje tipove auto delova nudite?",
            faqcontent1: "Naša ponuda obuhvata širok asortiman auto delova, uključujući delove za različite strane i domaće automobile. Bez obzira na vaše vozilo, kod nas ćete pronaći odgovarajuće delove.",
            faqlabel2:"Da li je moguća dostava auto delova na kućnu adresu?",
            faqcontent2: "Da, vršimo brzu dostavu auto delova na kućnu adresu putem kurirske službe, nudimo opciju plaćanja pouzećem.",
            faqlabel3:"Da li je moguće poručivanje iz inostranstva? ",
            faqcontent3: "Isporuka iz inostranstva je moguća za određene proizvode. Kontaktirajte nas putem telefona ili mejla kako bismo vam pružili informacije o dostupnosti ovog servisa za konkretnu robu.",
            faqlabel4:"Koji su podržane metode plaćanja?",
            faqcontent4: "Prihvatamo gotovinsko plaćanje i plaćanje čekovima u našoj radnji. Za dostavu nudimo opciju plaćanja pouzećem.",
            faqlabel5:"Da li nudite garanciju na auto delove?",
            faqcontent5: "Da, svi naši auto delovi dolaze s garancijom. Garancija može trajati do 6 meseci uz fiskalni račun iz ovlašćenog autoservisa ili u skladu s garancijom koju pružaju proizvođači delova.",
            faqlabel6:"Koja je politika za povraćaj delova i robe?",
            faqcontent6: "Za povraćaj nekorišćenih delova, potreban je fiskalni račun kao potvrda kupovine. Ako su delovi već ugrađeni, zahteva se popunjavanje reklamacionog zapisnika uz priložen fiskalni račun. Nakon uspešnog reklamacionog postupka, primenjuju se odgovarajuće procedure.",
            faqlabel7:"Da li nudite ugrađivanje određenih auto delova na licu mesta?",
            faqcontent7: "Ugradnju auto delova ne obavljamo u našoj radnji, osim za najjednostavnije delove kao što su brisači, antene i slično.",
            review: "Recenzije",
            serbia: "Srbija",
            monday: "Ponedeljak",
            friday: "Petak",
            saturday: "Subota"
        }
        },
    },
    fallbackLng: "rs",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        useSuspense: false,
        wait: true
    }
  });

export default i18n;